import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from "../components/Seo"

const Home = () => (
  <Layout>
    <main className="container mx-auto flex flex-col-reverse rounded-xl bg-transparent pb-10 shadow-md md:my-20 md:max-w-5xl md:flex-row md:bg-white md:pb-0 2xl:my-40">
      <div className="w-full p-4 text-center md:basis-3/5 md:p-12 md:text-left">
        <h1 className="mt-0 text-center text-2xl font-bold text-black md:mt-5 md:text-left md:text-4xl">
          MD Furkanul Islam
        </h1>
        <p className="mt-2 max-w-md text-center text-sm capitalize text-sub-headline  md:text-justify md:text-lg">
          Entrepreneur, Full-stack developer with six years of experience.
          Working on both front-end and back-end. Areas of interest are back-end
          and blockchain engineering. Institutional Degree in Software and
          Robotics industry. Learning new technologies has become one of my
          hobbies. Photography, traveling and mixing song is taken most of my
          leisure time.
        </p>
        <Link
          className="mt-5 inline-block rounded-full border-2 border-none bg-button py-2 px-10 text-lg text-white transition duration-1000 ease-out hover:bg-indigo-500 hover:ease-in"
          to="/services"
        >
          Services
        </Link>
      </div>
      <div className="mx-auto w-full bg-transparent p-10 pb-0 text-center md:basis-2/5 md:bg-button md:p-12 md:shadow-md">
        <StaticImage
          src="../assets/images/md.png"
          alt="MD Furkanul Islam"
          as="div"
          layout="fullWidth"
          placeholder="tracedSVG"
          className="img-profile overflow-hidden rounded-full"
          imgClassName="rounded-full"
        />
      </div>
    </main>
  </Layout>
)

export default Home

export const Head = () => (
  <SEO
    title="About Me"
    description={undefined}
    pathname={undefined}
    children={undefined}
  ></SEO>
)
